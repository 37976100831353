"use client";

import { getTraits } from "@bigspring/core-components/helpers";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useMeQuery } from "@gql/generated/graphql";
import { storeCompanyProfileExternalId, storeWorkspaces } from "@utils/auth";
import { BaseModel } from "../models/BaseModel";
import { getIdentifyData } from "@utils/helpers";
import { typewriterTrack } from "@lib/events";
import { useSegment } from "../Segment";
import { CompanyProfile } from "./models/CompanyProfile";
import { setMaintenanceMode } from "@utils/maintenanceMode";

interface Context {
  user: CompanyProfile;
  isLogged: boolean;
  refetch: () => void;
}

const Context = createContext<Context>({
  user: new CompanyProfile(),
  isLogged: false,
  refetch: () => void 0,
});

export function AuthenticationProvider({
  children,
}: PropsWithChildren<unknown>) {
  const segment = useSegment();

  const { data: rawData, refetch } = useMeQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: getTraits("pwa"),
  });

  const data = useMemo(
    () => new CompanyProfile(rawData?.meV2),
    [rawData?.meV2]
  );

  useEffect(() => {
    // TODO: With these workspaces set we can validate in the middleware whether the workspace is available or not
    // in order to allow pass or redirect to first available workspace
    const workspaces = data.profile.workspaces.map((workspace) => ({
      companyProfileId: workspace.id,
      companyExternalId: workspace.companyExternalId,
    }));

    storeWorkspaces(workspaces);
    storeCompanyProfileExternalId(data.externalId);
    setMaintenanceMode(false);

    // We just need to execute this effect once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.id && data.company.id) {
      const identify = async () => {
        const identifyData = getIdentifyData(data, data.company);
        if (identifyData) {
          await segment.identify(identifyData);
        } else {
          console.error(
            "🔴 Analytics: there was a problem identifying the user."
          );
        }
        if (process.env.NEXT_PUBLIC_SEGMENT_ENABLED) {
          typewriterTrack("userLoggedIn", {
            companyExternalId: data.company.externalId,
            profileExternalId: data.externalId,
            // FIXME: we were requesting this data to the server even when the server doesn't know this. We have to fix it.
            method: null,
          });
        }
      };

      // TODO: Handle this in login method to centralized the logic there
      void identify();
    }
  }, [data, data.company, segment]);

  BaseModel.setUserCompanyProfileId(data.id);

  return (
    <Context.Provider
      value={{
        user: data,
        isLogged: !!rawData?.meV2,
        refetch,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useAuth() {
  return useContext(Context);
}
